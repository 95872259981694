<template>
  <div class="container">
    <UserComponent>
      <template #user="{ user }">
        <header class="mt-2 is-fullwidth">
          <div
            class="
              is-flex is-align-items-center is-justify-content-space-between
            "
          >
            <img src="@/assets/logo.png" alt="Dzamlinggar Logo" />
            <UserProfile v-if="user" :user="user" />
            <LoginComponent v-else />
          </div>
          <h1>Feedback System</h1>
          <!-- <div class="columns">
            <div class="column mb-5 is-one-third">
              <div class="select is-fullwidth">
                <select>
                  <option disabled>Select #hashtag</option>
                  <option>Sangha Solutions</option>
                  <option>General Feedback</option>
                </select>
              </div>
            </div>
            <div class="column mb-5 is-one-third">
              <input type="search" class="input is-fullwidth" placeholder="Search" />
            </div>
          </div> -->
          <!-- <p>"Si ustedes son felices nosotros mas" - The Webcast Team</p> -->
        </header>
        <UpVote v-if="user" :user="user" />
      </template>
    </UserComponent>
  </div>
</template>

<script>
import LoginComponent from "./components/LoginComponent.vue";
import UserComponent from "./components/UserComponent.vue";
import UserProfile from "./components/UserProfile.vue";
import UpVote from "./components/UpVote.vue";
export default {
  name: "App",
  components: {
    UserComponent,
    LoginComponent,
    UserProfile,
    UpVote,
  },
};
</script>

<style>
</style>
