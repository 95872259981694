import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

import "firebase/storage";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD2N4TFM_n8YJDH6Nq-AI6uFPbk4gTeNXc",
  authDomain: "feedback-431da.firebaseapp.com",
  projectId: "feedback-431da",
  storageBucket: "feedback-431da.appspot.com",
  messagingSenderId: "777394417672",
  appId: "1:777394417672:web:0df44c2e88d8c7442f61bd",
  measurementId: "G-XT5WL926LQ",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const googleAuthProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);
