<template>
  <div class="is-flex is-align-items-center is-justify-content-space-between is-fullwidth">
    <button class="button is-link m-3">{{ user.email }}</button>
    <button class="button is-danger m-3" @click="auth.signOut()">Sign Out</button>
  </div>
</template>

<script>
import { auth } from '../firebase';

export default {
  props: ['user'],
  data() {
    return { 
          auth 
    }
  }
}
</script>