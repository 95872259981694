<template>
  <button class="button is-link" @click="googleSignIn()">Sign In with Google</button>
</template>

<script>
import {  signInWithPopup } from "firebase/auth";
import { auth, googleAuthProvider } from '../firebase';


export default {
  data() {
    return {
      signInWithPopup
    }
  },
  methods: {
    googleSignIn: function () {
      signInWithPopup(auth, googleAuthProvider)
        // .catch((err) => {
        //   err;
        //   // console.log(err); // This will give you all the information needed to further debug any errors
        // });
    },
  },
}
</script>