<template>
  <div>
    <div v-for="feedback in feedbacks" :key="feedback.id" class="box">
      <div class="columns">
        <div class="column is-one-fifth">
          <!-- <figure class="image is-64x64">
            <img class="is-rounded" :src="feedback.user.photoURL" />
          </figure>
          <div class="has-text-weight-bold">
            {{ feedback.user.displayName }}
          </div> -->
          <div>{{ dateTime(feedback.date) }}</div>
        </div>
        <div class="column is-size-4">
          {{ feedback.content }}
        </div>
        <div
          class="
            column
            is-1
            is-flex
            is-flex-direction-column
            is-align-items-end
            is-justify-content-space-around
            is-align-content-center
          "
        >
          <div class="feedback-up">
            <button
              :class="{ 'is-link': isUpVote(feedback) }"
              class="button"
              @click="upvote(feedback)"
            >
              <span class="icon">
                <i class="fa fa-arrow-up"></i>
              </span>
            </button>
          </div>
          <div class="feedback-count">
            <span class="is-size-2">{{ calculateVotes(feedback) }}</span>
          </div>
          <div class="feedback-up">
            <button
              :class="{ 'is-link': isDownVote(feedback) }"
              class="button"
              @click="downvote(feedback)"
            >
              <span class="icon">
                <i class="fa fa-arrow-down"></i>
              </span>
            </button>
          </div>
        </div>
        <div
          class="
            column
            is-1 is-flex is-align-items-end is-flex-direction-column
          "
        >
          <div
            v-if="feedback.user.uid == auth.currentUser.uid"
            class="dropdown is-right is-hoverable"
          >
            <div class="dropdown-trigger">
              <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
              >
                <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
              </button>
            </div>
            <div id="dropdown-menu" class="dropdown-menu" role="menu">
              <div class="dropdown-content">
                <a class="dropdown-item" @click="deleteFeedback(feedback)">
                  <i class="fa fa-trash"></i>
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <form class="is-full-width" @submit.prevent="addFeedback(content)">
        <div class="field">
          <label class="label">Message</label>
          <div class="control">
            <textarea
              v-model="content"
              class="textarea"
              placeholder="Which topic would you like to open in Sangha Solutions"
            ></textarea>
          </div>
        </div>
        <button class="button" type="submit">Submit</button>
      </form>
    </div>
    <div class="box">
      <div class="is-size-2">Thank You!</div>
      <div class="is-size-5">
        For any bug report or improvment write to
        <a href="mailto:it@dzamlinggar.org">it@dzamlinggar.org</a>
      </div>
    </div>
  </div>
</template>


<script>
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  doc,
  arrayRemove,
  arrayUnion,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import moment from "moment";
import { db, auth } from "../firebase";

export default {
  data() {
    return {
      feedbacks: [],
      auth,
    };
  },
  created() {
    this.fetchFeedbacks();
  },
  methods: {
    deleteFeedback(feedback) {
      if (confirm("Are you sure you want to delete this feedback?")) {
        this.feedbacks = this.feedbacks.filter((f) => f.id !== feedback.id);
        updateDoc(doc(db, "feedbacks", feedback.id), {
          deleted: true,
        });
      }
    },
    dateTime(value) {
      return moment(value).format("DD MMM YY");
    },
    fetchFeedbacks() {
      const q = query(
        collection(db, "feedbacks"),
        where("deleted", "==", false),
        orderBy("vote", "desc")
      );
      onSnapshot(q, (querySnapshot) => {
        this.feedbacks = [];
        querySnapshot.forEach((doc) => {
          this.feedbacks.push({ ...doc.data(), id: doc.id });
        });
      });
    },
    calculateVotes(feedback) {
      const upvotes = feedback.upvotes?.length || 0;
      const downvotes = feedback.downvotes?.length || 0;
      return upvotes - downvotes;
    },
    async addFeedback(content) {
      const user = auth.currentUser;
      const feedback = {
        content,
        date: new Date().toLocaleString(),
        user: {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
        },
        upvotes: [],
        downvotes: [],
        deleted: false,
      };
      await addDoc(collection(db, "feedbacks"), feedback);
    },
    isUpVote(feedback) {
      const user = auth.currentUser;
      return feedback.upvotes?.includes(user.uid);
    },
    isDownVote(feedback) {
      const user = auth.currentUser;
      return feedback.downvotes?.includes(user.uid);
    },
    async upvote(feedback) {
      const f = feedback;
      if (this.isUpVote(f)) {
        await updateDoc(doc(db, "feedbacks", f.id), {
          upvotes: arrayRemove(auth.currentUser.uid),
          vote: this.calculateVotes(f) - 1,
        });
        return;
      }
      if (this.isDownVote(f)) {
        await updateDoc(doc(db, "feedbacks", f.id), {
          downvotes: arrayRemove(auth.currentUser.uid),
          upvotes: arrayUnion(auth.currentUser.uid),
          vote: this.calculateVotes(f) + 2,
        });
        return;
      }
      await updateDoc(doc(db, "feedbacks", f.id), {
        downvotes: arrayRemove(auth.currentUser.uid),
        upvotes: arrayUnion(auth.currentUser.uid),
        vote: this.calculateVotes(f) + 1,
      });
    },
    async downvote(feedback) {
      const f = feedback;
      if (this.isDownVote(f)) {
        await updateDoc(doc(db, "feedbacks", f.id), {
          downvotes: arrayRemove(auth.currentUser.uid),
          vote: this.calculateVotes(f) + 1,
        });
        return;
      }
      if (this.isUpVote(f)) {
        await updateDoc(doc(db, "feedbacks", f.id), {
          downvotes: arrayUnion(auth.currentUser.uid),
          upvotes: arrayRemove(auth.currentUser.uid),
          vote: this.calculateVotes(f) - 2,
        });
        return;
      }
      await updateDoc(doc(db, "feedbacks", f.id), {
        downvotes: arrayUnion(auth.currentUser.uid),
        upvotes: arrayRemove(auth.currentUser.uid),
        vote: this.calculateVotes(f) - 1,
      });
    },
  },
};
</script>

